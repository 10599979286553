import React from "react";
import { graphql } from "gatsby";
import { RichText, Date } from "prismic-reactjs";
import { withPreview } from "gatsby-source-prismic";
import {
  ImageCaption,
  Quote,
  Text,
  ImageGallery,
  VideoMedia,
} from "../components/slices";
import SEO from "../components/seo";
import Layout from "../components/layout/index";

const PostSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "text":
          return (
            <div key={index}>
              <Text slice={slice} />
            </div>
          );
        case "quote":
          return (
            <div key={index}>
              <Quote slice={slice} />
            </div>
          );
        case "banner_with_caption":
          return (
            <div key={index}>
              <ImageCaption slice={slice} />
            </div>
          );
        case "image_gallery":
          return (
            <div key={index}>
              <ImageGallery slice={slice} />
            </div>
          );
        case "call_to_action":
          return (
            <div key={index}>
              <VideoMedia slice={slice} />
            </div>
          );
        default:
      }
    })();
    return res;
  });
};

const PostBody = ({ blogPost }) => {
  return (
    <>
      <div
        style={{
          padding: "0.5em 0em",
        }}
      >
        <h1>
          {RichText.asText(blogPost.title.raw).length !== 0 &&
            RichText.asText(blogPost.title.raw)}
        </h1>
        <div
          style={{
            color: "rgba(117, 117, 117, 1)",
            padding: "0.6em 0em",
            fontSize: "0.85rem",
          }}
        >
          <time>
            {new Intl.DateTimeFormat("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }).format(Date(blogPost.date))}
          </time>
        </div>
      </div>
      <PostSlices slices={blogPost.body} />
    </>
  );
};

const PostApp = ({
  data,
  pageContext: {
    data: { title },
    url,
  },
}) => {
  return (
    <Layout>
      <SEO title={`${title.text} (App)`} />
      <div style={{ padding: "1.5em 1.5em", maxWidth: 700, margin: "0 auto" }}>
        {data.prismicPostApp !== null && (
          <PostBody
            blogPost={data.prismicPostApp.data}
            site={data.site.siteMetadata.siteUrl}
            url={url}
          />
        )}
      </div>
    </Layout>
  );
};

export default withPreview(PostApp);

export const query = graphql`
  query ($language: String!, $uid: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    prismicPostApp(uid: { eq: $uid }, lang: { eq: $language }) {
      id
      uid
      lang
      type
      url
      data {
        title {
          raw
        }
        description {
          text
        }
        date
        categories {
          category {
            id
            document {
              ... on PrismicCategory {
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ... on PrismicPostAppBodyText {
            slice_label
            slice_type
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicPostAppBodyQuote {
            slice_label
            slice_type
            primary {
              quote {
                raw
              }
              name_of_the_author {
                raw
              }
            }
          }
          ... on PrismicPostAppBodyBannerWithCaption {
            id
            slice_label
            slice_type
            primary {
              image_banner {
                alt
                url
              }
              description {
                raw
              }
            }
          }
          ... on PrismicPostAppBodyImageGallery {
            id
            slice_label
            slice_type
            primary {
              name_of_the_gallery {
                raw
              }
            }
            items {
              image_captions {
                raw
              }
              gallery_image {
                url
              }
            }
          }
          ... on PrismicPostAppBodyCallToAction {
            id
            slice_label
            slice_type
            items {
              video_media {
                url
              }
            }
          }
        }
      }
    }
  }
`;
